import { template as template_45e70f95ffda449fb87889908cb518df } from "@ember/template-compiler";
const FKLabel = template_45e70f95ffda449fb87889908cb518df(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
