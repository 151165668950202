import { template as template_7c6edd2324824b0c8003a757efd49c52 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7c6edd2324824b0c8003a757efd49c52(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
